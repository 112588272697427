import { DIALOG_SIZE, ID } from "@/libs/interfaces/layouts"
import { ColorPicker } from "@/libs/useful"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { ICategory, IFestival } from "@/modules/pe-fest-module/data/interfaces"
import { EditableText, Popover } from "@blueprintjs/core"
import { useState } from "react"
import { ResourceContent } from "./DestrictCard"

/* Protopia Ecosystem component */
export interface ICategoryCardProps {
    item: ICategory
    festival: IFestival
    onDelete: ( id: ID ) => void
    onChange: ( field: string, value: any ) => void
}
const CategoryCard = (props: ICategoryCardProps): JSX.Element => {
    const [isContentEdit, setIsContentEdit] = useState<boolean>( false ) 

    const onEditContent = (bool: boolean) => {
        setIsContentEdit(bool)
    }
    const onChangeTitle = (title: string) => {
        props.onChange("title", title)
    } 
    const onChangeContent = (content: string) => {
        props.onChange("content", content)
    }
    const onChangeColor = (color: string) => {
        props.onChange("color", color)
    }

    return <div className={`pe-category-edit-card-container pe-track-edit-card-container`}> 
        <div className="d-flex flex-column flex-grow-100">
            
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 track-edit-title"
                    alwaysRenderInput={true} 
                    disabled={false}
                    placeholder="Edit title..."
                    selectAllOnFocus={true} 
                    value={props.item.title}
                    onChange={onChangeTitle}
                /> 
            </div>
            <ResourceContent
                content={props.item.content || ""}
                isContentEdit={isContentEdit}
                onChangeContent={onChangeContent}
                onEditContent={onEditContent}
            />            
            <div className="footer"> 
                <Popover content={ 
                    <ColorPicker color={ props.item.color || "#FFFFFF" } onChoose={onChangeColor} /> 
                }>  
                    <div className='pe-color-picker-example' style={{ backgroundColor: props.item.color?.toString() || "#FFFFFF" }}/> 
                </Popover>  
                <div className="position-relative d-flex">
                    <ConfirmButton
                        buttonIcon="trash"
                        onConfirm={() => props.onDelete( props.item.id )}
                        buttonLabel={<div className="d-md-flex d-none ">{__("Delete")}</div>}
                        buttonMinimal
                        dialogClasssName={DIALOG_SIZE.MINIMAL}
                    >
                        <div className="p-4">
                            {__("Really delete Category?")}
                        </div>
                    </ConfirmButton>          
                    <div className=" small bg-dark text-light m-1 py-1 px-2">
                    {
                        props.item.order
                    }
                    </div>   
                </div>
                
            </div>
        </div>        
    </div>
}
export default CategoryCard