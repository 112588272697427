import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import { IFestival } from "@/modules/pe-fest-module/data/interfaces"
import { Tab } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface ISettingsTabProps {
    festival: IFestival
    onValueChange: (field: string, value: any) => void
}
const SettingsTab = ({festival, onValueChange}: ISettingsTabProps): JSX.Element => {
    return <div className="row mt-4">
        <div className="col-12">
            <div className="lead mb-1">{__("Contacts")}</div> 
            <div className=" mb-1">{__("The contacts you specified will be listed on the pages of the Festival")}</div> 
            <FieldInput
                title={__("Link")}	 
                value={festival.link1}
                editable
                type={SCALAR_TYPES.URL}  
                onChange={value => onValueChange("link1", value)}
            />
            <FieldInput
                title={__("Link alternative")}	 
                value={festival.link2}
                editable
                type={SCALAR_TYPES.URL} 
                onChange={value => onValueChange("link2", value)}
            />
            <FieldInput
                title={__("VKontakte")}	 
                value={festival.vk}
                editable
                type={SCALAR_TYPES.URL}  
                onChange={value => onValueChange("vk", value)}
            />
            <FieldInput
                title={__("VKontakte alternative")}	 
                value={festival.vk2}
                editable
                type={SCALAR_TYPES.URL}  
                onChange={value => onValueChange("vk2", value)}
            />
            <FieldInput
                title={__("E-mail")}	 
                value={festival.email}
                editable
                type={SCALAR_TYPES.EMAIL} 
                onChange={value => onValueChange("email", value)}
            />
            <FieldInput
                title={__("E-mail alternative")} 
                value={festival.email2}
                editable
                type={SCALAR_TYPES.EMAIL} 
                onChange={value => onValueChange("email2", value)}
            />
            <FieldInput
                title={__("Telegramm")} 
                value={festival.telegramm}
                editable
                type={SCALAR_TYPES.URL}  
                onChange={value => onValueChange("telegramm", value)}
            />
            <FieldInput
                title={__("Telegramm alternative")} 
                value={festival.telegramm2}
                editable
                type={SCALAR_TYPES.URL} 
                onChange={value => onValueChange("telegramm2", value)}
            />
            <FieldInput 
                title={__("Phone")}	 
                value={festival.phone}
                className={` w-100 input dark form-control `}
                editable
                type={SCALAR_TYPES.PHONE}  
                onChange={value => onValueChange("phone", value)}
            />
            <FieldInput 
                title={__("Phone alternative")} 
                value={festival.phone2}
                className={` w-100 input dark form-control `}
                editable
                type={SCALAR_TYPES.PHONE} 
                onChange={value => onValueChange("phone2", value)}
            />
        </div>
    </div>
}
export default SettingsTab