import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, sprintf } from "@/libs/utilities"
import { ICategory, ICritery, IDestrict, IFestival, IGanre, IHoneycombs, ILand, IMilestone, IPEFestSchool, IProject, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { Button, ButtonGroup, Collapse, Intent } from "@blueprintjs/core"
import PatternFestivalChooser from "./add-festival-tabs/PatternFestivalChooser"
import PELandZip from "./PELandZip"
import { useState } from "react"
import { AppToaster } from "@/libs/useful"

/* Protopia Ecosystem component */
export interface ISourceContentProps {
    mode: MODE
    onSetMode : (mode: MODE) => void
    onLand: (land:ILand) => void
    land: ILand
    onSetTracks: (items: ITrack[]) => void
    onSetDestricts: (items: IDestrict[]) => void
    onSetCategories: (items: ICategory[]) => void
    onSetCriteries: (items: ICritery[]) => void
    onSetMilestones: (items: IMilestone[]) => void
    onSetProjects: (items: IProject[]) => void
    onSetSchools: (items: IPEFestSchool[]) => void
    onSetGanres: (items: IGanre[]) => void
    onSetHoneycombs: (items: IHoneycombs[]) => void
    onSetLand: (items: ILand) => void
    onSetFestival: (items: IFestival) => void
}

export enum MODE {
    NOTHING,
    LAND,
    FILE,
}

const SourceContent = ({mode, onSetMode, onLand, land, onSetTracks, onSetGanres, onSetDestricts, onSetLand, onSetFestival, onSetSchools, onSetCriteries, onSetMilestones, onSetProjects, onSetCategories, onSetHoneycombs, ...props }: ISourceContentProps): JSX.Element => { 
    const [selectLand, setSelectLand] = useState<ILand>( {id:-1} as ILand )
    const onClean = () => {
        onSetLand({} as ILand)
        onSetFestival({} as IFestival)
        onSetTracks([])
        onSetDestricts([])
        onSetCriteries([])
        onSetMilestones([])
        onSetProjects([])
        onSetCategories([])
        onSetSchools([])
        onSetGanres([])
        onSetHoneycombs([])
        AppToaster.show({
            message: __("Clear Event data successfully")
        })
    }
    const onChooseLand = (land: ILand) => {
        setSelectLand(land)
        AppToaster.show({
            message: sprintf(__("Chose %s. Import source now"), land.name)
        })
    }
    
    return <div className='pe-source-content-container w-100'>
        <div className="lead text-center my-3">
            {__("Choose source for create new Event")}
        </div>
        <ButtonGroup className="w-100 flex-centered" >
            <Button className="m-1 rounded-pill px-3" minimal={mode !== MODE.NOTHING} onClick={()=> onSetMode(MODE.NOTHING)}>
                { __("pure") }
            </Button> 
            <Button className="m-1 rounded-pill px-3" minimal={mode !== MODE.LAND} onClick={()=> onSetMode(MODE.LAND)}>
                { __("from this Landscape") }
            </Button> 
            <Button className="m-1 rounded-pill px-3" minimal={mode !== MODE.FILE} onClick={()=> onSetMode(MODE.FILE)}>
                { __("from file") }
            </Button> 
        </ButtonGroup>
        <Collapse isOpen={mode === MODE.NOTHING} className="w-100 "> 
        <div className="w-100 flex-centered p-4">
                <ConfirmButton
                    buttonLarge
                    buttonLabel={__("Clean all data")}
                    buttonIntent={Intent.DANGER}
                    buttonClassName="px-4 py-3"
                    onConfirm={onClean}
                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                >
                    <div className="p-4">
                        {__("Really clear all data?")}
                    </div>
                </ConfirmButton> 
            </div>
        </Collapse>
        <Collapse isOpen={mode === MODE.LAND} className="w-100 "> 
            <PatternFestivalChooser onChoose={ onChooseLand } festival={ land }  />
            <div className={`w-100 flex-centered ${ !!selectLand && selectLand?.id !== -1 ? "" : "d-none" }`}>
                <Button large minimal intent={Intent.DANGER} onClick={() => onLand( selectLand )}>
                    {__("Import source")}
                </Button>
            </div>
        </Collapse>
        <Collapse isOpen={mode === MODE.FILE} className="w-100 "> 
            <PELandZip 
                onSetTracks = {onSetTracks}
                onSetDestricts = {onSetDestricts}
                onSetLand={onSetLand}
                onSetFestival={onSetFestival} 
                onSetCategories={onSetCategories}
                onSetCriteries={onSetCriteries}
                onSetMilestones={onSetMilestones}
                onSetProjects={onSetProjects}
                onSetSchools={onSetSchools}
                onSetGanres={onSetGanres}
            />
        </Collapse> 
    </div>
}
export default SourceContent