import { Color } from '@/libs/interfaces/layouts'
import { LoaderMinimal } from '@/libs/useful/Loading'
import { getContrastColor } from '@/libs/utilities'
import { GET_FEED_ACTION } from '@/modules/pe-basic-module/data/actionTypes'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { Tag } from '@blueprintjs/core'
import { useEffect, useState } from 'react'
import { getVisibleValue, schema } from 'src/libs/layouts'
import actions from 'src/modules/pe-basic-module/data/actions'
import IScalarForm, { SCALAR_TYPES } from '../interface/IScalarForm'
import Scalar from './Scalar'
import { Select } from "@/libs/useful"

const ExternalSingle = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        notClear
        enabledForm={<ExternalSingleEnabled {...props} notClear />}
        desabledForm={<ExternalSingleDesabled {...props} />}
    />
} 
export default ExternalSingle 

const ExternalSingleEnabled = ( props: IScalarForm ): JSX.Element => { 

    const getDefPaging =() => {
        if(!props.paging) {
            return ""
        }
        return props.paging
    }
    const [ seed, ] = useState<string>( props.seed ||  props.component + " " + getDefPaging() + " " +  props.landId )
    const [ isLoading, setIsLoading] = useState<boolean>( true )
    const items: any[] = useDataStore((state:any) => {
        if( props.component ){
           return state[props.component + seed] || []
        }
        return []
    }) || [] 
    useEffect(() => { 
        actions(
            GET_FEED_ACTION, 
            { 
                data_type: props.component ,
                paging: getDefPaging(),
                count: 3000,
                land_id: props.landId,
                seed 
            }
        )
        .then(() => setIsLoading(false))

        return () => {
            const st = {...useDataStore.getState() } 
            st[ props.component + seed ] = [] 
            useDataStore.setState( st )
        }
    }, [])
    const onChange = (evt: any) => { 
        const value = evt?.id 
        if( props.onChange ) {
            props.onChange( items.filter( item => item.id === value )[0],  props.field, "" )
        }   
    } 
    //console.log( props.value ) 
    const  vv = getVisibleValue( props.component! ) || SCALAR_TYPES.TITLE  

    const selectItems = items
    .filter((item) => {
        //если указан фильтр типа: { name: [ variants ], name2: [varuiants] }
        if( props.filter )
        { 
            const filters = Object.keys(props.filter).map(( filter: string ) => {
                // console.log(  
                //     props.filter[ filter ],
                //     filter,
                //     item[filter], 
                //     item[filter].filter( ( f: any ) => !!props.filter ? props.filter[ filter ].includes( f ): true )
                // )
                return Array.isArray( item[filter] )
                    ?
                    !!item[filter].filter( ( f: any ) => props.filter ? props.filter[ filter ].includes( f ): true ).length
                    :
                    props.filter![ filter ].includes( item[filter] )
            })  
            return filters.some(value => value )
        }
        return true
    })
    .sort((a:any, b:any) => {
        if( !props.sort ) return 1
        if(a[ props.sort.field ] > b[ props.sort.field ] ) return props.sort.order === "ASC" ?  1 : -1
        if(a[ props.sort.field ] < b[ props.sort.field ] ) return props.sort.order === "ASC" ?  -1 : 1
        return 0
    })
    .map((item, i) => { 
        return { ...item, title: item[vv], key: i } 
    })

    return <div 
        className={`${ props.className ? props.className :` d-flex justify-content-center my-2 w-100 ` } ${ isLoading ? "opacity_5" : "" }`}
    > 
        <Select 
            isMulti={ false }
            items={ [{ id: -1, title: "--", __typename: props.component, key: -1 }, ...selectItems] }
            value={ props.value || {id:-1} }
            onChange={ onChange }
            placeholder={props.placeholder}
        /> 
    </div>
} 
 
const ExternalSingleDesabled = ( props: IScalarForm ): JSX.Element => {
    const fills: Color[] | null =  schema()[props.component || "Post"  ]?.admin_data?.fill
    const color : string = Array.isArray( fills ) ? fills[0].toString() : "#000000" 
    const visibled_value2 = getVisibleValue( props.component || "Post" )
    const vv2 = visibled_value2 || SCALAR_TYPES.TITLE
    return <Tag 
        round
        className="px-4 py-1 m-1"
        style={{
            backgroundColor: color,
            color: getContrastColor( color )
        }}
    >
        { !!props.value ? props.value[vv2] : "--" }
    </Tag>
} 
 