import { ID } from "@/libs/interfaces/layouts"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { IFestival, IHoneycombs, IPEFestSchool, IProject, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { EditableText } from "@blueprintjs/core"
import { useState } from "react"
import { ResourceContent, ResourceIllustration } from "./DestrictCard"
import ResourceParentBtn from "./ResourceParentBtn"

/* Protopia Ecosystem component */
export interface IProjectCardProps {
    item: IProject
    festival: IFestival
    onDelete: ( id: ID ) => void
    onChange: ( field: string, value: any ) => void
}
const ProjectCard = (props: IProjectCardProps): JSX.Element => { 
    const [isContentEdit, setIsContentEdit] = useState<boolean>( false ) 
 
    const onEditContent = (bool: boolean) => {
        setIsContentEdit(bool)
    }
    const onChangeTitle = (title: string) => {
        props.onChange("title", title)
    }
    const onThumbnail = (thumbnail: string, data: any = {}) => {
        props.onChange( "thumbnail", thumbnail )
    }  
    const onChangeContent = (content: string) => {
        props.onChange("content", content)
    }
    const onTrack = (item: ITrack) => {
        props.onChange("track", item?.id || "-1")
    }
    const onSchool = (item: IPEFestSchool) => {
        props.onChange("fmru_school", item?.id || "-1")
    }
    const onHoneycombs = (item: IHoneycombs) => {
        props.onChange("honeycombs", item?.id || "-1")
    }
    return <div className={`pe-track-edit-card-container `}>
        <div className="thumb position-relative" > 
            <ResourceIllustration 
                illustration={props.item?.thumbnail || ""}
                onChange={onThumbnail}
            /> 
            <div className="position-absolute bottom right small bg-dark text-light m-2 py-1 px-2">
                {
                    props.item.order
                }
            </div>
        </div>
        <div className="d-flex flex-column flex-grow-100">
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 track-edit-title"
                    alwaysRenderInput={true} 
                    disabled={false}
                    placeholder="Edit title..."
                    selectAllOnFocus={true} 
                    value={props.item.title}
                    onChange={onChangeTitle}
                /> 
            </div>
            <ResourceContent
                content={props.item.content || ""}
                isContentEdit={isContentEdit}
                onChangeContent={onChangeContent}
                onEditContent={onEditContent}
            />            
            <div className="footer">
                <div className="position-relative d-flex align-items-center flex-grow-100"> 
                    {/* <div className="d-md-flex d-none small opacity_75 pl-2">{__("Destrict")}:</div>
                    <SchoolDestrictBtn 
                        onChoose={ onChangeSchool } 
                        destrict={ props.item.destrict }
                    /> */}
                    <ResourceParentBtn<ITrack>
                        resources={props.item.track}
                        dataTypeLabel="Tracks"
                        onChoose={onTrack}
                    />
                    <ResourceParentBtn<IPEFestSchool>
                        resources={props.item.fmru_school}
                        dataTypeLabel="Schools"
                        onChoose={onSchool}
                    />
                    <ResourceParentBtn<IHoneycombs>
                        resources={props.item.honeycombs}
                        dataTypeLabel="Honeycombs"
                        onChoose={onHoneycombs}
                    />
                </div>
                <div className="position-relative flex-centered">
                    <ConfirmButton
                        buttonIcon="trash"
                        onConfirm={() => props.onDelete( props.item.id )}
                        buttonMinimal
                    >
                        <div className="p-4">
                            {__("Really delete ProjectCard?")}
                        </div>
                    </ConfirmButton> 
                </div>
            </div>
        </div>        
    </div>
}
export default ProjectCard
