import { MediaChooser } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { IFestival, ILand } from "@/modules/pe-fest-module/data/interfaces"
import { Alignment, Navbar, Tab, TabId, TabPanel, Tabs } from "@blueprintjs/core"
import { useState } from "react"
import EventTab from "./add-festival-tabs/EventTab"
import SettingsTab from "./add-festival-tabs/SettingsTab"
import { useFestExport } from "@/modules/pe-fest-module/data/store"

/* Protopia Ecosystem component */
export interface ILandscapeContentProps { 
}
const SettingContent = ({ ...props}: ILandscapeContentProps): JSX.Element => {
    const festival: IFestival = useFestExport((state: any) => state.state) || {}
    const land: ILand = { event_types: [], images: [], ...useFestExport((state: any) => state.land) }

    const setFestival = (f: IFestival) => {
        useFestExport.setState({festival: {...festival, ...f}})
    }
    const setLand = (lnd: ILand) => {
        useFestExport.setState({land: {...land, ...lnd}})
    }
    const onValueChange = (field: string, value: any) => {

    }
    const [navbarTabId, setNavbarTabId] = useState<TabId>("settings")
    const [panels, ] = useState<any>({
        settings: <SettingsTab festival={festival} onValueChange={onValueChange} />,
        "event-data": <EventTab land={land} setLand={setLand} />
    })
    return <div className="p-4 mb-2 position-relative">
        <Navbar className="bg-transparent">                         
            <Navbar.Group align={Alignment.LEFT}>
                <Tabs id="new-fest-settings" onChange={(nId:TabId) => setNavbarTabId(nId)} selectedTabId={navbarTabId}> 
                    <Tab
                        id="settings"
                        title={__("Settings")} 
                    />
                    
                    <Tab
                        id="event-data"
                        title="Event data" 
                    />
                </Tabs>
            </Navbar.Group>
        </Navbar>
        <div className="lead mb-1">{__("Default image")}</div>
        <div className="row">
            <div className=" col-12 ">
                <MediaChooser
                    url={festival.defaultThumbnail || "" }
                    prefix={"icon"}
                    height={230}
                    width={"100%"}
                    padding={5}
                    onChange={ () => {} }
                    id={ festival.defaultThumbnail || "" }
                    isURLHide={ true }
                    isDescr={ false }
                    isFileNameHide
                    hideLib
                    ID="headerImg"
                    limitSize={ 4000000 }
                />
                <div className="small mt-1 text-right">{
                    __("This is the header image for the home page.")}
                </div>
            </div> 
        </div>
        <TabPanel
            id={navbarTabId}
            selectedTabId={navbarTabId}
            parentId="new-fest-settings"
            panel={ panels[ navbarTabId ] }
        />
    </div>
}
export default SettingContent