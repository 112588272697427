import { ID, Role } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __, translitterate } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_SERVICE_OPTIONS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { FET_FESTIVAL_EXTENDS_ACTION, GET_FESTIVAL_SETTINGS_ACTION } from "@/modules/pe-fest-module/data/actionTypes"
import { ICategory, ICritery, IDestrict, IFestForm, IFestival, IGanre, IHoneycombs, ILand, IMilestone, IPEFestSchool, IProject, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { Collapse } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { actions as festAction } from "../../../../data/actions"
import RatingSystemContent from "../RatingSystemContent"
import SettingContent from "../SettingContent"
import SourceContent, { MODE } from "../SourceContent"
import TrackEditCard from "../TrackEditCard"
import DestrictCard from "./DestrictCard" 
import ResourceContent from "./ResourceContent"
import { useFestExport } from "@/modules/pe-fest-module/data/store"
import SchoolCard from "./SchoolCard"
import ProjectCard from "./ProjectCard"
import MilestoneCard from "./MilestoneCard"

/* Protopia Ecosystem component */
export interface IStewTabProps {
   
}

interface DataElement {
    id: ID
    title: string
    icon: string, 
    panel: () => JSX.Element
    isActive: () => boolean
}
interface DataSource {
    [key: string] : DataElement
} 

const StewTab = (props: IStewTabProps): JSX.Element => { 
    const [ festival, setFestival] = useState<IFestival>({} as IFestival) 
    const [ land, setLand] = useState<ILand>({} as ILand) 
    const [ sourceMode, setSourceMode ] = useState<MODE>( MODE.NOTHING ) 
    const [ n, setN] = useState<number>( 0 ) 
    const [ isLoading, setIsLoading ]   = useState<boolean>(false) 
    const [ roles, setRoles ]           = useState<Role[]>([]) 
    const [ isOpen, setIsOpen ]         = useState<keyof DataSource>("source")
    const [ item, setItem ]             = useState<IFestForm>({ title:"", domain:"" } as IFestForm) 
    const [ tracks, setTracks ]         = useState<ITrack[]>( [] )
    const [ destricts, setDestricts ]   = useState<IDestrict[]>( [] )
    const [ schools, setSchools]        = useState<IPEFestSchool[]>( [] )
    const [ honeycombs, setHoneycombs]  = useState<IHoneycombs[]>( [] )
    const [ milestones, setMilestones]  = useState<IMilestone[]>( [] )
    const [ categories, setCategories]  = useState<ICategory[]>( [] )
    const [ criteries, setCriteries]    = useState<ICritery[]>( [] )
    const [ ganres, setGanres ]         = useState<IGanre[]>( [] ) 
    const [ projects, setProjects ]     = useState<IProject[]>( [] ) 
    
    
    useEffect(() => { 
        actions(GET_SERVICE_OPTIONS_ACTION, {})
            .then((resp: any) => { 
                setRoles([...resp.roles, "SuperAdmin"])
            })
    }, [])

    const onLand = ( land: ILand ) => {
        console.log( land )
        setLand(land)
        if(land?.id) {
            setIsLoading(true)
            Promise.all([
                festAction(FET_FESTIVAL_EXTENDS_ACTION, {id: land.id})
                    .then(( resp ) => {
                        // console.log( resp )
                        const tracks: ITrack[] = resp.getPEFestTracks.map((m: ITrack, i: number) => ({...m, order: i + 1}))
                        const destricts: IDestrict[] = resp.getPEFestDestricts.map((m: IDestrict, i: number) => ({...m, order: i + 1}))
                        const schools: IPEFestSchool[] = resp.getPEFestSchools.map((m: IPEFestSchool, i: number) => ({...m, order: i + 1}))
                        const categories: ICategory[] = resp.getPEFestCategorys.map((m: ICategory, i: number) => ({...m, order: i + 1}))
                        const ganres: IGanre[] = resp.getPEFestGanres.map((m: IGanre, i: number) => ({...m, order: i + 1}))
                        const Honeycombs: IHoneycombs[] = resp.getPEFestHoneycombss.map((m: IHoneycombs, i: number) => ({...m, order: i + 1}))
                        const criteries: ICritery[] = resp.getPEFestCriterys.map((m: ICritery, i: number) => ({...m, order: i + 1}))
                        const milestone: IMilestone[] = resp.getPEFestMilestones.map((m: IMilestone, i: number) => ({...m, order: i + 1}))
                        onSetTracks( tracks )
                        onSetDestrict( destricts )
                        onSetSchools( schools )
                        onSetGanres( ganres ) 
                        onSetCategories( categories ) 
                        onSetHoneycombs( Honeycombs ) 
                        onSetCriteries( criteries ) 
                        onSetMilestones( milestone ) 
                    }),
                festAction(GET_FESTIVAL_SETTINGS_ACTION, {id: land.id})
                    .then(( resp ) => {
                        setFestival( resp )
                    })
            ])
            .finally(() => setIsLoading( false ))
        }
        else {

        }
    }
    
    const onSetFestival = (item: IFestival) => {
        setFestival(item)
        useFestExport.setState({festival: item})
    }
    
    const onSetLand = (item: ILand) => {
        setLand(item)
        useFestExport.setState({land: item})
    }
 
    const onOpen = (field: keyof DataSource | "", i: number) => {
        setN(i)
        setIsOpen( isOpen === field ? "" : field )
    }
    const onSetTracks = ( _tracks: ITrack[]) => {
        setTracks( _tracks )
        useFestExport.setState({tracks: _tracks})
    }
    const onSetSchools = (_schools: IPEFestSchool[]) => {
        setSchools( _schools )
        useFestExport.setState({schools: _schools})
    }
    const onSetDestrict = (_destricts: IDestrict[]) => {
        setDestricts( _destricts )
        useFestExport.setState({destricts: _destricts})
    }
    const onSetProjects = (_projects: IProject[]) => {
        setProjects( _projects )
        useFestExport.setState({projects: _projects})
    } 
    const onSetHoneycombs = (_honeycombs: IHoneycombs[]) => {
        setHoneycombs( _honeycombs )
        useFestExport.setState({honeycombs: _honeycombs})
    } 
    const onSetMilestones = (_ms: IMilestone[]) => {
        setMilestones( _ms )
        useFestExport.setState({milestones: _ms})
    } 
    const onSetCategories = (_categories: ICategory[]) => {
        setCategories( _categories )
        useFestExport.setState({categories: _categories})
    }  
    const onSetCriteries = (_criteries: ICritery[]) => {
        setCriteries( _criteries )
        useFestExport.setState({criteries: _criteries})
    }  
    const onSetGanres = (_ganres: IGanre[]) => {
        setGanres( _ganres )
        useFestExport.setState({ganres: _ganres})
    }  
    const onRestoreTracks = () => {
        const items = useDataStore.getState().PEFestTrack
        setTracks( items )
        useFestExport.setState({tracks: items})
    }
    const onRestoreSchools = () => {
        const items = useDataStore.getState().PEFestSchool
        setSchools( items )
        useFestExport.setState({schools: items})
    }
    const onRestoreDestricts = () => {
        const items = useDataStore.getState().PEFestDestrict 
        setDestricts( items )
        useFestExport.setState({destricts: items})
    }
    const onRestoreProjects = () => {
        const items = useDataStore.getState().PEFestProject
        setProjects( items )
        useFestExport.setState({projects: items})
    }
    const onRestoreGanres = () => {
        const items = useDataStore.getState().PEFestGanre
        setGanres( items )
        useFestExport.setState({ganres: items})
    }
    const onRestoreHoneycombs = () => {
        const items = useDataStore.getState().PEFestHoneycombs
        setHoneycombs( items )
        useFestExport.setState({honeycombs: items})
    }
    const changeFestival = ( site_id: ID) => {
        festAction(
            GET_FESTIVAL_SETTINGS_ACTION, 
            { id: site_id }
        )
        .then((resp: IFestival) => { 
            setFestival(resp)
            useFestExport.setState({festival: resp})
        })
    }
    const onField = ( value: any, field: keyof IFestForm ) => {
        let d: any = {}
        if( field === "title" ) {
            const defDomain: string = translitterate()( item.title, "" ).toLowerCase() 
            if( defDomain === item.domain ) {
                d = { domain: translitterate()( value, "-" ).toLowerCase() }
            }
        }
        if(field === "finishDate" || field === "startDate") {
            value = Math.floor(value / 1000)
        } 
        const _item = { ...item, [field]: value, ...d }
        setItem(_item) 
        useFestExport.setState({item: _item})
    }
    
    const data: DataSource = ({
        source: {
            id: "source",
            title: "Source",
            icon: "/assets/img/fest/source-icon.svg", 
            panel: () => <SourceContent 
                mode={sourceMode}
                onSetMode={setSourceMode}
                land={land} 
                onLand={onLand} 
                onSetTracks = {onSetTracks}
                onSetDestricts = {onSetDestrict}
                onSetCategories={onSetCategories}
                onSetCriteries={onSetCriteries}
                onSetMilestones={onSetMilestones}
                onSetProjects={onSetProjects}
                onSetHoneycombs={onSetHoneycombs}
                onSetLand={onSetLand}
                onSetFestival={onSetFestival}
                onSetSchools={onSetSchools}
                onSetGanres={onSetGanres}
            />,
            isActive: () => true
        },
        date: {
            id: "date",
            title: "Dates",
            icon: "/assets/img/fest/calendar-icon.svg", 
            panel: () => <div className="container h-100"> 
                <div className="row ">
                    <div className="col-md-6">
                        <FieldInput
                            value={item.startDate}
                            title={__("Start date")}
                            vertical={VerticalType.VERTICAL}
                            editable
                            type={SCALAR_TYPES.DATE}  
                            onChange={ value => onField( value * 1000, "startDate")}
                        />
                    </div>
                    <div className="col-md-6">
                        <FieldInput
                            value={item.finishDate}
                            title={__("Finish date")}
                            vertical={VerticalType.VERTICAL}
                            editable
                            type={SCALAR_TYPES.DATE}  
                            onChange={ value => onField( value * 1000, "finishDate")}
                        />
                    </div>
                </div>
            </div>,
            isActive: () => !!item.startDate || !!item.finishDate
        },
        setting: {
            id: "setting",
            title: "Settings",
            icon: "/assets/img/fest/settings-icon.svg", 
            panel: () => <SettingContent />,
            isActive: () => !!Object.keys(festival).length
        },
        tracks: {
            id: "tracks",
            title: "Tracks",
            icon: "/assets/img/fest/tracks-icon.svg", 
            panel: () => <ResourceContent 
                Component={ TrackEditCard }
                festival={festival} 
                resources={tracks} 
                setResources={onSetTracks} 
                onRestore={onRestoreTracks}
                dataTypeName={__("PEFestTrack")}
            />,
            isActive: () => !!tracks.length
        },
        destricts: {
            id: "destricts",
            title: "Destricts",
            icon: "/assets/img/fest/destrict-icon.svg", 
            panel: () => <ResourceContent 
                Component={ DestrictCard }
                festival={festival} 
                resources={destricts} 
                setResources={onSetDestrict} 
                onRestore={onRestoreDestricts}
                dataTypeName={__("PEFestSchool")}
            />,
            isActive: () => !!destricts.length
        },
        schools: {
            id: "schools",
            title: "Education organizations",
            icon: "/assets/img/fest/schools-icon.svg", 
            panel: () => <ResourceContent 
                Component={ SchoolCard }
                festival={festival} 
                resources={schools} 
                setResources={onSetSchools} 
                onRestore={onRestoreSchools}
                dataTypeName={__("PEFestSchool")}
            />,
            isActive: () => !!schools.length
        },
        projects: {
            id: "projects",
            title: "Projects",
            icon: "/assets/img/fest/projects-icon.svg", 
            panel: () => <ResourceContent 
                Component={ ProjectCard }
                festival={festival}
                resources={projects} 
                setResources={onSetProjects} 
                onRestore={onRestoreProjects}
                dataTypeName={__("PEFestProject")}
            />,
            isActive: () => !!projects.length
        },
        ratings: {
            id: "ratings",
            title: "Rating system",
            icon: "/assets/img/fest/rating-icon.svg", 
            panel: () => <RatingSystemContent
                festival={festival}
                milestones={milestones}
                categories={categories}
                criteries={criteries}  
                onSetMilestones={onSetMilestones}
                onSetCategories={onSetCategories}
                onSetCriteries={onSetCriteries}
            />,
            isActive: () => !!criteries.length
        },
        cooperation: {
            id: "cooperation",
            title: "Cooperation",
            icon: "/assets/img/fest/cooperation-icon.svg", 
            panel: () => <div className="my-3">
                <div className="ml-5 my-2 d-flex">
                    <div className="pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/honeycombs-icon.svg)`}} /> 
                    <div className="lead">
                        {__("Honeycombs")}
                    </div>
                </div>
                <ResourceContent 
                    Component={ MilestoneCard }
                    festival={festival}
                    resources={honeycombs} 
                    setResources={onSetHoneycombs} 
                    onRestore={onRestoreHoneycombs}
                    dataTypeName={__("PEFestHoneycombs")}
                />
            </div>,
            isActive: () => !!honeycombs.length
        },
        ganres: {
            id: "ganres",
            title: "Ganres",
            icon: "/assets/img/fest/ganre-icon.svg", 
            panel: () => <ResourceContent 
                Component={ DestrictCard }
                festival={festival}
                resources={ganres} 
                setResources={onSetGanres} 
                onRestore={onRestoreGanres}
                dataTypeName={__("PEFestGanre")}
            />,
            isActive: () => !!ganres.length
        },
    }) 

    return <div className={`pe-stew-tab-container ${isLoading ? "opacity_5 untouchble" : "" }  p-md-3`}>  
        <div className=" px-md-4 d-flex flex-wrap">
        {
            Object.keys(data).map((key, i) => ( 
                <div className="hint hint--top" data-hint={ __(data[key].title) } key={key}> 
                    <div 
                        className={`navi-btn  ${ n === i ? "active" : "" }  ${ data[key].isActive() ? "" : "opacity_5" }`}  
                        onClick={() => onOpen(key, i) }
                    >
                        <div className="icon" style={{backgroundImage:`url(${ data[key].icon })`}}>
    
                        </div>
                        <span className="navi-title">{__(data[key].title)}</span>
                    </div> 
                </div>
            ))
        }
        </div>
        <div className="my-3 display-6 text-center">
            { __(data[isOpen].title) } 
        </div> 
        {
            Object.keys(data).map((key, i) => ( 
                <Collapse isOpen={isOpen === key} className="w-100 " key={key}> 
                    { data[ key ].panel() } 
                </Collapse>
            ))
        }
        
        {/* <div className=" px-md-4 d-flex flex-wrap">
            <div className="hint hint--top" data-hint={__("Source")}> 
                <div 
                    className={`navi-btn  ${ n === 0 ? "active" : "" } `}  
                    onClick={() => onOpen('source', 0) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/source-icon.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Source")}</span>
                </div> 
            </div> 
            <div className="hint hint--top" data-hint={__("Dates")}> 
                <div 
                    className={`navi-btn  ${ n === 1 ? "active" : "" } ${ !!item.startDate || !!item.finishDate ? "" : "opacity_5" }`}  
                    onClick={() => onOpen('date', 1) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/calendar-icon.svg)`}}>

                    </div>
                    <span className={`navi-title`}>{__("Dates")}</span>
                </div>
            </div>
            <div className="hint hint--top" data-hint={__("Settings")}> 
                <div 
                    className={`navi-btn  ${ n === 2 ? "active" : "" }  ${ !!Object.keys(festival).length ? "" : "opacity_5" }`}  
                    onClick={() => onOpen( 'setting', 2 ) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/settings-icon.svg)`}} />
                    <span className="navi-title">{__("Settings")} </span>
                </div>
            </div>
            <div className="hint hint--top" data-hint={__("Tracks")}> 
                <div 
                    className={`navi-btn  ${ n === 3 ? "active" : "" } ${ !!tracks.length ? "" : "opacity_5" }`}  
                    onClick={() => onOpen( 'tracks', 3 ) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/tracks-icon.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Tracks")}</span>
                </div>
                </div>
            <div className="hint hint--top" data-hint={__("Destricts")}> 
                <div 
                    className={`navi-btn  ${ n === 4 ? "active" : "" } ${ !!destricts.length ? "" : "opacity_5" }`}  
                    onClick={() => onOpen( 'destricts', 4 ) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/destrict-icon.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Destricts")}</span>
                </div>
            </div>
            <div className="hint hint--top" data-hint={__("Education organizations")}> 
                <div 
                    className={`navi-btn  ${ n === 5 ? "active" : "" } ${ !!schools.length ? "" : "opacity_5" }`}  
                    onClick={() => onOpen( 'schools', 5 ) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/schools-icon.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Education organizations")}</span>
                </div>
            </div>
            <div className="hint hint--top" data-hint={__("Projects")}> 
                <div 
                    className={`navi-btn  ${ n === 6 ? "active" : "" } ${ !!projects.length ? "" : "opacity_5" }`}  
                    onClick={() => onOpen( 'projects', 6 ) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/project-settings.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Projects")}</span>
                </div>
            </div> 
            <div className="hint hint--top" data-hint={__("Rating system")}> 
                <div 
                    className={`navi-btn  ${ n === 7 ? "active" : "" } ${ !!criteries.length ? "" : "opacity_5" }`}  
                    onClick={() => onOpen( 'ratings', 7 ) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/rating-icon.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Rating system")}</span>
                </div>
            </div> 
            <div className="hint hint--top" data-hint={__("Cooperation")}> 
                <div 
                    className={`navi-btn  ${ n === 8 ? "active" : "" } ${ !!honeycombs.length ? "" : "opacity_5" }`}  
                    onClick={() => onOpen( 'cooperation', 8 ) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/cooperation-icon.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Cooperation")}</span>
                </div>
            </div> 
            <div className="hint hint--top" data-hint={__("Ganres")}> 
                <div 
                    className={`navi-btn  ${ n === 9 ? "active" : "" } ${ !!ganres.length ? "" : "opacity_5" }`}  
                    onClick={() => onOpen( 'ganres', 9 ) }
                >
                    <div className="icon" style={{backgroundImage:`url(/assets/img/fest/ganre-icon.svg)`}}>

                    </div>
                    <span className="navi-title">{__("Ganres")}</span>
                </div>
            </div> 
        </div> 
        <div className="my-3 display-7 text-center">
            {
                isOpen
            }    
        </div> 
        <Collapse isOpen={isOpen === "source"}    className="w-100 "> 
            <SourceContent 
                onLand={onLand} 
                land={land} 
                onSetTracks = {onSetTracks}
                onSetDestricts = {onSetDestrict}
                onSetCategories={onSetCategories}
                onSetCriteries={onSetCriteries}
                onSetMilestones={onSetMilestones}
                onSetProjects={onSetProjects}
                onSetHoneycombs={onSetHoneycombs}
                onSetLand={onSetLand}
                onSetFestival={onSetFestival}
                onSetSchools={onSetSchools}
                onSetGanres={onSetGanres}
            /> 
        </Collapse>
        <Collapse isOpen={isOpen === "date"}    className="w-100 ">
            <div className="container h-100"> 
                <div className="row ">
                    <div className="col-md-6">
                        <FieldInput
                            value={item.startDate}
                            title={__("Start date")}
                            vertical={VerticalType.VERTICAL}
                            editable
                            type={SCALAR_TYPES.DATE}  
                            onChange={ value => onField( value * 1000, "startDate")}
                        />
                    </div>
                    <div className="col-md-6">
                        <FieldInput
                            value={item.finishDate}
                            title={__("Finish date")}
                            vertical={VerticalType.VERTICAL}
                            editable
                            type={SCALAR_TYPES.DATE}  
                            onChange={ value => onField( value * 1000, "finishDate")}
                        />
                    </div>
                </div>
            </div>
        </Collapse>
        <Collapse isOpen={isOpen === "setting"} className="w-100 ">
            <SettingContent />
        </Collapse>
        <Collapse isOpen={isOpen === "tracks"}  className="w-100 "> 
            <ResourceContent 
                Component={ TrackEditCard }
                festival={festival} 
                resources={tracks} 
                setResources={onSetTracks} 
                onRestore={onRestoreTracks}
                dataTypeName={__("PEFestTrack")}
            /> 
        </Collapse>
        <Collapse isOpen={isOpen === "destricts"}  className="w-100 ">
            <ResourceContent 
                Component={ DestrictCard }
                festival={festival} 
                resources={destricts} 
                setResources={onSetDestrict} 
                onRestore={onRestoreDestricts}
                dataTypeName={__("PEFestSchool")}
            />
        </Collapse>
        <Collapse isOpen={isOpen === "schools"}  className="w-100 ">
            <ResourceContent 
                Component={ SchoolCard }
                festival={festival} 
                resources={schools} 
                setResources={onSetSchools} 
                onRestore={onRestoreSchools}
                dataTypeName={__("PEFestSchool")}
            />
        </Collapse>
        <Collapse isOpen={isOpen === "projects"}  className="w-100 ">
            <ResourceContent 
                Component={ ProjectCard }
                festival={festival}
                resources={projects} 
                setResources={onSetProjects} 
                onRestore={onRestoreProjects}
                dataTypeName={__("PEFestProject")}
            />
        </Collapse>
        <Collapse isOpen={isOpen === "ratings"}  className="w-100 "> 
            <RatingSystemContent
                festival={festival}
                milestones={milestones}
                categories={categories}
                criteries={criteries}  
                onSetMilestones={onSetMilestones}
                onSetCategories={onSetCategories}
                onSetCriteries={onSetCriteries}
            />
        </Collapse>
        <Collapse isOpen={isOpen === "cooperation"}  className="w-100 ">
            <div className="my-3">
                <div className="ml-5 my-2 d-flex">
                    <div className="pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/honeycombs-icon.svg)`}} /> 
                    <div className="lead">
                        {__("Honeycombs")}
                    </div>
                </div>
                <ResourceContent 
                    Component={ MilestoneCard }
                    festival={festival}
                    resources={honeycombs} 
                    setResources={onSetHoneycombs} 
                    onRestore={onRestoreHoneycombs}
                    dataTypeName={__("PEFestHoneycombs")}
                />
            </div>
        </Collapse>
        <Collapse isOpen={isOpen === "ganres"}  className="w-100 ">
            <ResourceContent 
                Component={ DestrictCard }
                festival={festival}
                resources={ganres} 
                setResources={onSetGanres} 
                onRestore={onRestoreGanres}
                dataTypeName={__("PEFestGanre")}
            />
        </Collapse> */}
    </div>
}
export default StewTab