import { __ } from "@/libs/utilities"
import { ICategory, ICritery, IFestival, IMilestone } from "@/modules/pe-fest-module/data/interfaces"
import CategoryCard from "./add-festival-tabs/CategoryCard"
import CriteryCard from "./add-festival-tabs/CriteryCard"
import MilestoneCard from "./add-festival-tabs/MilestoneCard"
import ResourceContent from "./add-festival-tabs/ResourceContent"

/* Protopia Ecosystem component */
export interface IRatingSystemContentProps {
    festival: IFestival 
    milestones: IMilestone[]
    onSetMilestones:(items: IMilestone[]) => void
    categories: ICategory[]
    onSetCategories:(items: ICategory[]) => void
    criteries: ICritery[]
    onSetCriteries:(items: ICritery[]) => void
}
const RatingSystemContent = ({
    festival, milestones, onSetMilestones, 
    categories, onSetCategories,
    criteries, onSetCriteries,

}: IRatingSystemContentProps): JSX.Element => {
    return <div className='pe-rating-system-content-container'>
        <div className="my-3">
            <div className="ml-5 my-2 d-flex">
                <div className="pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/milestone-icon.svg)`}} /> 
                <div className="lead">
                    {__("Milestones")}
                </div>
            </div>
            <ResourceContent 
                Component={ MilestoneCard }
                festival={festival}
                resources={milestones} 
                setResources={onSetMilestones} 
                onRestore={() => {} }
                dataTypeName={__("PEFestMilestone")}
            />
        </div>
        <div className="my-3">
            <div className="ml-5 my-2 d-flex">
                <div className="pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/category-icon.svg)`}} /> 
                <div className="lead">
                    {__("Categories")}
                </div>
            </div>
            <ResourceContent 
                Component={ CategoryCard }
                festival={festival}
                resources={categories} 
                setResources={onSetCategories} 
                onRestore={() => {} }
                dataTypeName={__("PEFestCategory")}
            />
        </div>
        <div className="my-3">
            <div className="ml-5 my-2 d-flex">
                <div className="pe-fest-resource-icon color-force-contrast" style={{backgroundImage:`url(/assets/img/fest/critery-icon.svg)`}} /> 
                <div className="lead">
                    {__("Criteries")}
                </div>
            </div>
            <ResourceContent 
                Component={ CriteryCard }
                festival={festival}
                resources={criteries} 
                setResources={onSetCriteries} 
                onRestore={() => {} }
                dataTypeName={__("PEFestCritery")}
            />
        </div>
    </div>
}
export default RatingSystemContent