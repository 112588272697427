
import { Button, Classes, IconName, Intent, MaybeElement, Menu, MenuItem, Tag, Text } from "@blueprintjs/core";
import { ItemListRenderer, type ItemRenderer, MultiSelect, Select as SingleSelect } from "@blueprintjs/select"; 
import { SyntheticEvent, useState } from "react";
import { ID } from "../../interfaces/layouts";

/* Protopia Ecosystem component */
export interface ISelectProps<T> {
   items: T[]
   value: T[] | undefined
   onChange: (items:T[] | T ) => void
   placeholder?: string
   menuIcon? : IconName | MaybeElement
   isMulti?:boolean
}

export interface ISelectItem {
   id: ID
   title: string
   key: string | number
}

function Select<T extends ISelectItem>({items, value, onChange, placeholder, menuIcon, isMulti, ...props}: ISelectProps<T>): JSX.Element {
   const [selectedItem, setSelectedItem]  = useState<T | undefined>();
   const [selectItems, setSelectItems]    = useState<T[]>( Array.isArray(value) ? value : [] )
   const [activeItem, setActiveItem]      = useState<T>()

   const isItemSelected = (item: T): boolean => {
      return selectItems?.indexOf(item) !== -1
   }
   const renderItem: ItemRenderer<T> = (item: T, props: any) => { 
      return <MenuItem 
            icon={menuIcon}
            role="listbox" 
            roleStructure="listoption"
            selected={ isItemSelected(item) }
            shouldDismissPopover={false}
            text={`${item.title}`} 
            labelElement={
               <div className="small opacity_5 ">
                  {`${item.id}`} 
               </div>
            } 
            key={ item.key }
            onClick={ () => {
               isMulti 
                  ? 
                  onItemSelect(item) 
                  :  
                  onItemClick(item)
            }}
         /> 
   }

   const renderMenu: ItemListRenderer<T> = ({ items, itemsParentRef, query, renderItem, menuProps }) => {
      const renderedItems = items.map(renderItem).filter(item => item != null);
      return (
         <Menu role="listbox" ulRef={itemsParentRef} {...menuProps}> 
            {renderedItems}
         </Menu>
      );
  }


   const onItemClick= (item : T) => { 
      setSelectedItem(item) 
      onChange( item )
   }
   const renderCustomTarget = (selectedItems: T[]) => <MultiSelectCustomTarget count={selectedItems.length} />
   
   const renderTag = (item: T) => {
      return item.title
   }

   const onItemSelect = (item: T, event?: SyntheticEvent<HTMLElement, Event> ) => { 
      console.log( item, event )
      const selected = isItemSelected(item)
      if(selected){
         const i: number = selectItems.indexOf(item)
         onRemove(item, i)
      }
      else {
         setSelectItems([...selectItems, item])
         setActiveItem(item)
         onChange( [...selectItems, item] )
      }

   }
   const onItemsPaste = (item: T[] ) => { 
      //console.log( item )
   }
   const onClear =( ) => {
      setSelectItems([])
      onChange([])
   }
   const onRemove =( item: T, i: number ) => { 
      let _sI: T[] = [...selectItems]
      _sI.splice(i, 1)
      setSelectItems( _sI )
      onChange( _sI )
   }
   return isMulti
      ?
      <MultiSelect<T>
         itemRenderer={ renderItem }
         items={items} 
         tagRenderer={ renderTag }
         menuProps={{ "aria-label": "items" }}
         noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
         onItemSelect={ onItemSelect }
         onItemsPaste={onItemsPaste}
         onClear={onClear}
         onRemove={onRemove}
         placeholder={placeholder}
         selectedItems={selectItems}
         popoverProps={{ matchTargetWidth: true, minimal: true }}
         // customTarget={ renderCustomTarget }
         popoverTargetProps={{ }} 
         fill 
      /> 
      :
      <SingleSelect<T>
         itemListRenderer={renderMenu}
         itemRenderer={ renderItem }
         items={items}  
         menuProps={{ "aria-label": "items" }}
         noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
         onItemSelect={ setSelectedItem }
         placeholder={ placeholder } 
         popoverProps={{ matchTargetWidth: true, minimal: true }}
         // customTarget={ renderCustomTarget }
         popoverTargetProps={{ }} 
         fill 
      >
         <Button text={selectedItem?.title || placeholder } fill minimal textClassName={ Classes.TEXT_MUTED } /> 
      </SingleSelect>
}
export default Select

interface IMultiSelectCustomTargetProps {
   count: number;
}
const MultiSelectCustomTarget: React.FC<IMultiSelectCustomTargetProps> = ({ count }) => {
   return (
       <Tag
           large={true}
           round={true}
           minimal={true}
           interactive={true}
           intent={Intent.NONE}
           className="w-100"
       >
           <div className="docs-custom-target-content w-100 d-flex justify-content-between align-items-center">
               <Text className={"docs-custom-target-text"}>Custom Target</Text>
               <Tag intent={Intent.NONE} round={true}>
                   {count}
               </Tag>
           </div>
       </Tag>
   );
}