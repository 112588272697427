import { LayoutIcon } from "@/libs/useful"
import { __, getContrastColor } from "@/libs/utilities"
import { IFestival, ILand } from "@/modules/pe-fest-module/data/interfaces"
import { eventTypes } from "@/modules/pe-topos-module/data/mocks/eventTypes"
import FestivalSiteGalleryForm from "../../FestivalSiteGalleryForm"


const sz = 24
/* Protopia Ecosystem component */
export interface IEventTabProps { 
    land: ILand
    setLand: (land: ILand) => void 
}
const EventTab = ({land, setLand, }: IEventTabProps): JSX.Element => {

    const onEventTypes = (value: string) => {
        console.log( value)
        let event_types = [...land.event_types]
        if( !!event_types.filter(e => e === value).length ){
            event_types = event_types.filter(e => e !== value)
        }
        else {
            event_types.push(value)
        }
        setLand({
            ...land, 
            event_types 
        })
    }
    const setGallery = (value: string[], names: string[] ) => {
        setLand({
            ...land,
        })
    }
    return <div className="row mt-4">
        <div className="lead mb-3 col-12">{__("Set event type")}</div>
        <div className="d-flex flex-wrap col-12">
        {
            eventTypes().map((et, i) => {
                const enb: boolean = !!land.event_types?.filter(e => e === et.title).length
                return <div className="d-flex mt-0" key={et.id}>
                    <div 
                        className=" m-1 d-flex align-items-center rounded-pill pointer"  
                        key={ et.id } 
                        style={{ 
                            background: enb ? et.color : "#777777", 
                            height: sz + 12,
                            opacity: enb ? 1 : 0.75
                        }}
                        onClick={ () => onEventTypes( et.title ) }
                    >
                        <span className="topos-placetype-label-icon" style={{ height: sz + 12, width:sz + 18 }}>
                            <LayoutIcon
                                src={ et.icon }
                                className=""
                                style={{ width:sz, height: sz }}
                            />
                        </span>
                        <span 
                            className="px-3 py-1" 
                            style={{ 
                                color: enb ? getContrastColor(et.color): "#FFFFFF" 
                            }}
                        >
                            { __( et.title ) }
                        </span>
                    </div>
                </div>
            })
        }            
        </div>
        <div className="lead mb-3 mt-4 col-12">{__("Set gallery")}</div>
        <div className="col-12">
            <FestivalSiteGalleryForm item={land} onChange={setGallery} />
        </div>
    </div>
}
export default EventTab