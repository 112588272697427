import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __ } from "@/libs/utilities"
import { Button, Intent } from "@blueprintjs/core"
import { useState } from "react"
import JSZip from 'jszip'; 
import { AppToaster } from "@/libs/useful"
import { ICategory, ICritery, IDestrict, IFestival, IGanre, ILand, IMilestone, IPEFestSchool, IProject, ITrack } from "@/modules/pe-fest-module/data/interfaces"

/* Protopia Ecosystem component */
export interface IPELandZipProps {
    onSetTracks: (items: ITrack[]) => void
    onSetDestricts: (items: IDestrict[]) => void
    onSetCategories: (items: ICategory[]) => void
    onSetCriteries: (items: ICritery[]) => void
    onSetMilestones: (items: IMilestone[]) => void
    onSetLand: (items: ILand) => void
    onSetFestival: (items: IFestival) => void
    onSetProjects: (items: IProject[]) => void
    onSetSchools: (items: IPEFestSchool[]) => void
    onSetGanres: (items: IGanre[]) => void
}
const PELandZip = ({onSetTracks, onSetDestricts, onSetFestival, onSetLand, onSetGanres, onSetSchools, onSetCategories, onSetMilestones, onSetCriteries, onSetProjects}: IPELandZipProps): JSX.Element => {
    const [file, setFile] = useState<any>()
    const onFile = (value:string, name: string, file: any) => { 
        setFile(file)
    }
    const onUnzip = () => {
        /* https://stuk.github.io/jszip/documentation/examples/read-local-file-api.html */
        var new_zip = new JSZip()
        new_zip.loadAsync(file)
            .then((zipped) => {
                zipped.file("data.json")?.async("string")
                    .then((text: string) => {
                        const json: any = JSON.parse( text )
                        console.log( json )
                        onSetTracks( json.PEFestTrack?.map( (m: any, i: number) => ({ ...m, order: i+1, id: m.ID})) )
                        onSetDestricts(json.PEFestDestrict?.map( (m: any, i: number) => ({ ...m, order: i+1, id: m.ID})))
                        onSetFestival(json.PEFestFestival )
                        onSetCategories(json.PEFestCategory?.map( (m: any, i: number) => ({ ...m, order: i+1, id: m.ID})) )
                        onSetCriteries(json.PEFestCritery?.map( (m: any, i: number) => ({ ...m, order: i+1, id: m.ID})) )
                        onSetMilestones(json.PEFestMilestone?.map( (m: any, i: number) => ({ ...m, order: i+1, id: m.ID})) )
                        onSetProjects(json.PEFestProject?.map( (m: any, i: number) => ({ ...m, order: i+1, id: m.ID})) )
                        onSetLand(json.Land)
                        onSetSchools(json.PEFestSchool?.map( (m: any, i: number) => ({ ...m, order: i+1, id: m.ID})))
                        onSetGanres(json.PEFestGanre?.map( (m: any, i: number) => ({ ...m, order: i+1, id: m.ID})))
                    })
                    .catch(err => {
                        console.log( err )
                        AppToaster.show({
                            message: <>
                                <div className="lead">{__("Invalid file content format")}</div>
                                <div className="mt-1">{__("Check the correctness of the recorded data or recreate the *peland file")}</div>
                            </>,
                            className: "p-3 flex-centered", 
                            intent: Intent.DANGER,
                            timeout: 10000
                        })
                    })
            }) 
            .catch(err => {
                console.error( err )
                AppToaster.show({
                    message: __("Invalid file format"),
                    className: "p-5 lead", 
                    intent: Intent.DANGER 
                })
            })
    }
    return <div className='pe-peland-zip-container'>
       <div className="p-4 w-100">
            <FieldInput
                type={ SCALAR_TYPES.UPLOAD }
                value={ file }
                editable 
                accept=".peland"
                vertical={ VerticalType.HORIZONTAL }
                title={ __( "get *.peland scenario file" ) }
                limitSize={ 60000 }
                onChange={ onFile }
                // onClear={ () => setFile(null) }
            />     
        </div>
        <div className="w-100 flex-centered">
            <Button large minimal intent={Intent.DANGER} onClick={onUnzip}>
                {__("Import source")}
            </Button>
        </div>
    </div>
}
export default PELandZip