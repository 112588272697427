import { DIALOG_SIZE, ID, URL } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { ScalableDialog } from "@/libs/useful"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, wordTrim } from "@/libs/utilities"
import { IDestrict, IFestival } from "@/modules/pe-fest-module/data/interfaces"
import { Button, ButtonGroup, EditableText, Intent } from "@blueprintjs/core"
import { useEffect, useRef, useState } from "react"

/* Protopia Ecosystem component */
export interface IDestrictProps {
    item: IDestrict
    festival: IFestival
    onDelete: ( id: ID ) => void
    onChange: ( field: string, value: any ) => void
}
const DestrictCard = (props: IDestrictProps): JSX.Element => { 
    const [isContentEdit, setIsContentEdit] = useState<boolean>( false ) 
 
    const onEditContent = (bool: boolean) => {
        setIsContentEdit(bool)
    }
    const onChangeTitle = (title: string) => {
        props.onChange("title", title)
    }
    const onThumbnail = (thumbnail: string, data: any = {}) => {
        props.onChange( "thumbnail", thumbnail )
    } 
    const onChangeContent = (content: string) => {
        props.onChange("content", content)
    }
    return <div className={`pe-track-edit-card-container `}>
        <div className="thumb position-relative" > 
            <ResourceIllustration 
                illustration={props.item?.thumbnail || ""}
                onChange={onThumbnail}
            /> 
            <div className="position-absolute bottom right small bg-dark text-light m-2 py-1 px-2">
                {
                    props.item.order
                }
            </div>
        </div>
        <div className="d-flex flex-column flex-grow-100">
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 track-edit-title"
                    alwaysRenderInput={true} 
                    disabled={false}
                    placeholder="Edit title..."
                    selectAllOnFocus={true} 
                    value={props.item.title}
                    onChange={onChangeTitle}
                /> 
            </div>
            <ResourceContent
                content={props.item.content}
                isContentEdit={isContentEdit}
                onChangeContent={onChangeContent}
                onEditContent={onEditContent}
            />            
            <div className="footer"> 
                <ConfirmButton
                    buttonIcon="trash"
                    onConfirm={() => props.onDelete( props.item.id )}
                    buttonLabel={<div className="d-md-flex d-none ">{__("Delete")}</div>}
                    buttonMinimal
                >
                    <div className="p-4">
                        {__("Really delete?")}
                    </div>
                </ConfirmButton> 
            </div>
        </div>        
    </div>
}
export default DestrictCard
 
export interface IResourceContentProps {
    isContentEdit: boolean
    content: string
    onChangeContent: (content: string) => void
    onEditContent : (bool:boolean) => void
}
export const ResourceContent = ({
    content, 
    isContentEdit,
    onChangeContent, 
    onEditContent,
    ...props
}: IResourceContentProps): JSX.Element => {
    const myRef = useRef( null )

    const onMouseLeaveHandler = (e: Event) => {
        const domNode: any = myRef.current;
        if (!domNode || !domNode.contains(e.target)) {
            //height = 0 
            onEditContent( false )
        } 
    }
    useEffect(() => {
        document.body.addEventListener("click", onMouseLeaveHandler)
        return () => document.body.removeEventListener("click", onMouseLeaveHandler)
    }, [])

    return <div 
        className="content position-relative " 
        onClick={ () =>  onEditContent( true ) } 
        ref={ myRef }
    >
        <EditableText
            className="track-content-edit w-100 "
            alwaysRenderInput={true} 
            //disabled={ !isContentEdit }
            multiline
            placeholder="Edit content..." 
            value={ isContentEdit && content ? content : wordTrim( content || "", 50 ) }
            minLines={5}
            maxLines={5}
            onChange={onChangeContent} 
        /> 
    </div> 
} 


/* Protopia Ecosystem component */
export interface ITrackThumbnailProps {
    thumbnail: URL
    onChange: (value: any, name: string) => void
}
export const ResourceThumbnail = ({...props}: ITrackThumbnailProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const [thumbnail, setThumbnail] = useState( props.thumbnail )
    const [thumbnailName, setThumbnailName] = useState( "" )
    const onChange = (value: string, field: string , data: any) => {
        console.log( data )
        setThumbnail (value )
        setThumbnailName( data.name )
    }
    const onUpdate = () => {
        setIsOpen(false)
        props.onChange(thumbnail, thumbnailName)
    }
    return <>
        <img 
            src={props.thumbnail} 
            alt="" 
            style={{ width: 35, cursor: "pointer" }} 
            onClick={() => setIsOpen(true) }
        />
        <ScalableDialog
            isOpen={isOpen}
            dialogSize={DIALOG_SIZE.MINIMAL}
            onClose={() => setIsOpen(false)}
        >
            <div className="w-100 p-4">
                <FieldInput
                    type={SCALAR_TYPES.MEDIA}
                    isURLHide
                    isFileNameHide
                    editable
                    value={thumbnail}
                    onChange={onChange}
                />
            </div>
            <ButtonGroup fill>
                <Button intent={Intent.DANGER} large onClick={ () => setIsOpen( false ) } >
                    {__( "Close" )}
                </Button>
                <Button intent={Intent.SUCCESS} large onClick={ onUpdate } >
                    {__( "Update" )}
                </Button>
            </ButtonGroup>
        </ScalableDialog>
    </>
}

/* Protopia Ecosystem component */
export interface ITrackIllustrationProps {
    illustration:string
    onChange: (illustration: string, illustrationName: string) => void
}
export const ResourceIllustration = (props: ITrackIllustrationProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const [thumbnail, setThumbnail] = useState( props.illustration )
    const [thumbnailName, setThumbnailName] = useState( "" )
    const onChange = (value: string, field: string , data: any) => {
        console.log( data )
        setThumbnail (value )
        setThumbnailName( data.name )
    }
    const onUpdate = () => {
        setIsOpen(false)
        props.onChange(thumbnail, thumbnailName)
    }
    return <>
        <FieldInput
            type={SCALAR_TYPES.MEDIA}
            isURLHide
            isFileNameHide
            editable
            height={"100%"}
            width={"100%"}
            value={thumbnail}
            onChange={onChange}
            notClear
            rowClassName={" m-0 h-100 " }
        />
        {/* <div 
            className="w-100 h-100 thumb"
            style={{ backgroundImage:`url(${props.illustration})`, cursor: "pointer" }}
            onClick={() => setIsOpen(true) }
        />
        <ScalableDialog
            isOpen={isOpen}
            dialogSize={DIALOG_SIZE.MINIMAL}
            onClose={() => setIsOpen(false)}
        >
            <div className="w-100 p-4">
                <FieldInput
                    type={SCALAR_TYPES.MEDIA}
                    isURLHide
                    isFileNameHide
                    editable
                    height={320}
                    width={320}
                    value={thumbnail}
                    onChange={onChange}
                />
            </div>
            <ButtonGroup fill>
                <Button intent={Intent.DANGER} large onClick={ () => setIsOpen( false ) } >
                    {__( "Close" )}
                </Button>
                <Button intent={Intent.SUCCESS} large onClick={ onUpdate } >
                    {__( "Update" )}
                </Button>
            </ButtonGroup>
        </ScalableDialog> */}
    </>
} 