
import gql from "graphql-tag"
import { UPDATE_SINGLE_TYPE, GET_OPTIONS_ACTION, UPDATE_OPTIONS_ACTION, UPDATE_DICTIONARY_ACTION } from "./actionTypes" 
import { DocumentNode } from "graphql"
import { useAppoloStore } from "@/settings/zustand"
import { GET_OPTIONS }  from "./graphql/GET_OPTIONS"
import { setRequestError } from "@/settings/errorControllers/setRequestError"

const asyncControllers = async (action: string, data: any) => {
    let response: any, mutation: DocumentNode, query: DocumentNode, query_name: string, mutationString:string, fields: any
    const apolloClient: any = useAppoloStore.getState().client
    try {
        switch (action) {
            case UPDATE_SINGLE_TYPE: 
                return response
            case GET_OPTIONS_ACTION:
                query = gql`${ GET_OPTIONS() }`
                response = await apolloClient.query({
                    query,
                    variables: data,
                })
                return response
            case UPDATE_DICTIONARY_ACTION:
                const json = JSON.stringify(data.dictionary).replaceAll('"', '\\"')
                console.log(JSON.stringify(data.dictionary))
                const m = `mutation changeDictionary {
                    changeDictionary(input: {
                        language: "ru-RU"
                        dictionary: "${ json }"
                    }) {
                        language
                        dictionary
                    }
                }`
                mutation = gql`${m}`
                response = await apolloClient.mutate({
                    mutation 
                })
                return response
            case UPDATE_OPTIONS_ACTION:
                mutation = gql`mutation changeOptions($input: OptionsInput) {
                    changeOptions(input: $input) {
                        name
                        description
                        adress
                        user_verify_account
                        thumbnail
                        default_img
                        vk
                        youtube
                        android
                        apple
                        help_url
                        email
                        vk_client_id
                        vk_client_secret
                        yandex_client_id
                        yandex_client_token
                        yandex_client_secret
                        telegramm_client_id
                        icon_media_term
                        user_media_term
                        test_media_term 
                    }
                }`
                console.log( data )
                console.log( mutation )
                response = await apolloClient.mutate({
                    mutation,
                    variables: {
                        input: data,
                    },
                })
                return response
            default:
                return new Promise<any>((resolve, reject) => { resolve(`Unknown action type: ${action}`) })
        }
    }
    catch( error: any ) {
      console.log( action, data, error )
      setRequestError( action, data, error ) 
     
      return response
    }
    finally {
      return response
    }
}
export default asyncControllers