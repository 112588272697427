import { IFeedProps } from "@/modules/pe-basic-module/views/core/FeedComponent"
import { eventTypes } from "@/modules/pe-topos-module/data/mocks/eventTypes"
import EventTypeLabel from "@/modules/pe-topos-module/views/labels/EventTypeLabel"
import { LayoutIcon } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { component_url } from "@/libs/utilities/links"
import { Tag } from "@blueprintjs/core"
import Moment from "react-moment"
import { NavLink } from "react-router-dom"
import { ILand } from "../../data/interfaces"
import PhaseLabel from "../PhaseLabel"
import { useEffect } from "react"
import addCSSClass from "@/libs/layout-app/utils/addCSSClass"
import removeCSSClass from "@/libs/layout-app/utils/removeCSSClass"

/* Protopia Ecosystem component */
export interface IFestivalButtonProps extends IFeedProps {
    item: ILand
}

const FestivalButton = (props: IFestivalButtonProps): JSX.Element => { 
  let colClass = " col-12 " 
  if( props.params?.col )
  {
      switch(parseInt( props.params?.col ))
      {
          case 2:
              colClass = " col-md-6 "
              break;
          case 3:
              colClass = " col-md-4 "
              break;
          case 4:
              colClass = " col-md-3 "
              break;
          case 1:
          default:                
              colClass = " col-12 "
              break;

      }
  }
    return <Card 
        icon={props.item.icon}
        thumbnail={props.item.defaultThumbnail}
        status={props.item.status}
        title={props.item.name}
        startDate={props.item.startDate}
        finishDate={props.item.finishDate}
        color={props.item.color}
        description={props.item.description}
        site={props.item}
        url={ `${component_url("FestivalView")}fest/${props.item.id.toString()}` }
        colClass={colClass}
    /> 
}
export default FestivalButton

const Card = (props: any) : JSX.Element => { 
  
  useEffect(() => {
    addCSSClass(
      `landCards-${props.site.id}`,  
      `.land-card-${props.site.id} {
        width: 100%;
        height: 380px;
        min-height: 125px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(${ props.site.defaultThumbnail });
        background-color: ${props.site.color}!important; 
      }`
    )
    return () => {
      removeCSSClass(`landCards-${props.site.id}`)
    }
  }, [])
  return <div className={`p-0 ${props.colClass}`}> 
    <NavLink to={props.url} >
      <div className={` card land-card-${props.site.id} pe-light text-center text-secondary border-0 card-top-img `} >
        <div className="position-absolute top left w-100 d-flex flex-column z-index-1 untouchble" style={{ height: 330 }}>
            <EventTypeLabel 
              item={{...props.site, eventTypes: eventTypes().filter(et => props.site.event_types?.includes(et.title))}} 
              className="position-absolute top left m-1" 
              isShort
            />
            <div  
              className="card-image bg-transparent"
              style={{ 
                width: "100%", 
                height: 100,
                minHeight: 100, 
              }}
          >
            <div className="card-image-icon bg-light">
                <LayoutIcon
                    height={33}
                    width={33}
                    style={{ height: 33, width: 33, margin: 7 }}
                    src={props.icon}
                />
            </div>
          </div>
          <div className="flex-grow-100"></div> 
          <div className="card-body d-flex flex-column align-items-center ">  
            <div className="card-text px-3 pb-3 w-100 flex-centered" >
              {
                props.startDate && <Tag minimal round className="mr-3 px-3 text-contrast-color"> 
                <Moment  locale="ru" format="D.MM.YYYY" >
                  { parseInt(props.startDate) * 1000 }
                </Moment>
              </Tag>
              }
              {
                props.finishDate && <Tag minimal round className="px-3 text-contrast-color">
                  <Moment  locale="ru" format="D.MM.YYYY" >
                    { parseInt(props.finishDate) * 1000 }
                  </Moment>
                </Tag>
              }
            </div> 
            <div className="card-text px-3 pb-2 text-force-contrast" dangerouslySetInnerHTML={{ __html: __(props.description) }}/>
            <h4 className="card-title p-0 mb-1">
              <span className="py-1 px-3 text-force-contrast" dangerouslySetInnerHTML={{ __html:__(props.title) }}/>
            </h4> 
          </div>
          <div className="position-absolute m-1 w-100 text-center bottom left z-index-2 opacity_75">
              <PhaseLabel phase={props.status} className="bg-transparent text-force-contrast small"/>
          </div>
        </div> 
      </div>
    </NavLink>
  </div>
}